<template>
  <router-view />
</template>
<script>
export default {
  name: "rank",
  mounted() {
    if (!this.$route.params.mode) {
      this.$router.replace({
        name: "rankmodepage",
        params: { mode: "single", page: 1 },
      });
    }
  },
  watch: {
    $route(to, from) {
      if (to.name == "rank") {
        if (!this.$route.params.mode) {
          this.$router.replace({
            name: "rankmodepage",
            params: { mode: "single", page: 1 },
          });
        }
      }
    },
  },
};
</script>
